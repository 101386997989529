import React from 'react';
import ImageClinicFront from './resources/ClinicFrontFull.jpg';
import ImageOffice from './resources/Office.jpg';
import GrahamProfile from './resources/GrahamPenningtonProfile.jpg';
import KateProfile from './resources/KatePenningtonProfile.jpg';
import {
    Link
} from 'react-router-dom';

export default function HomePage() {
    return(
        <div className="container">
            <div className="homepage-content">
                <p className="homepage-paragraph">
                We pride ourselves on providing intelligent, holistic and effective treatment strategies to help solve your health dilemmas.
                <br/><br/>
                We use the oldest forms of medicine known to man (Acupuncture and Herbal Medicine) which have been around for thousands of years, as well as an exciting, new approach for treating musculoskeletal problems (Bowen therapy). 
                <br/><br/>
                This clinic was established in 1991 and we are proud to have contributed to the health and wellbeing of the people of Warrnambool for the past three decades.
                </p>
                <div className='image-parent'>
                    <img style={{ width: "100%" }} src={ImageClinicFront} />
                </div>
            </div>
            <div className="homepage-content invert-flex-order">
                <div className='image-parent'>
                    <img style={{ width: "100%" }} src={ImageOffice} />
                </div>
                <p className="homepage-paragraph">
                We are passionate complementary health care providers. This means we help the body to heal itself, using holistic approaches.
                <br/><br/>
                This is reflected in our assessment and treatment techniques. We assist the body to realign and repair, working from a wider, more global perspective.
                <br/><br/>
                Each person is assessed holistically and then provided with a treatment based on their unique presentation.
                </p>
            </div>
            <div style={{ marginTop: "3rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h2 style={{ textAlign: "center", fontSize: "2rem", margin: "2rem", fontWeight: "700" }}>Our Practitioners</h2>
                <div className="practitioner-parent">
                    <PractitionerCard 
                        name="Graham Pennington" 
                        imgsrc={GrahamProfile} 
                        disciplines="Naturopathy, Acupuncture, Herbal Medicine, Bowen Therapy"
                        link="/graham"
                    />
                    <PractitionerCard 
                        name="Kate Pennington" 
                        imgsrc={KateProfile} 
                        disciplines="Acupuncture, Herbal Medicine, Bowen Therapy"
                        link="/kate"
                    />
                </div>
            </div>
        </div>
    );
}

function PractitionerCard(props) {
    return(
        <div className="practitioner-card">
            <img src={props.imgsrc} style={{ borderRadius: "50%" }}/>
            <h3 style={{ fontWeight: "700", fontSize: "1.25rem", margin: "1rem" }}>{props.name}</h3>
            <h4>{props.disciplines}</h4>
            <Link to={props.link}><h5 style={{ marginTop: "1rem", textDecoration: "underline", color: "initial" }}>Read More</h5></Link>
        </div>
    );
}