import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Map from '../../resources/Map.png';
import './Contact.css';

export default function Contact() {
    return(
        <div className='contact-parent'>
            <h2 className='contact-header'>Contact Us</h2>
            <div className='contact-details'>
                <div className='contact-detail'>
                    <FontAwesomeIcon className='contact-detail-icon' icon={faPhone}/>
                    <div className='contact-detail-text'>(03) 5561 2442</div>
                </div>
                <div className='contact-detail'>
                    <FontAwesomeIcon className='contact-detail-icon' icon={faEnvelope}/>
                    <div className='contact-detail-text'>g-penno@bigpond.com</div>
                </div>
            </div>
            <div style={{ fontSize: "1.5rem", marginTop: "6rem", marginBottom: "2rem" }}>We are located at 861 Raglan Parade, Warrnambool</div>
            <img src={Map} id="map"/>
        </div>
    );
}