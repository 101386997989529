import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import Header from './components/Header/Header';
import HomePage from './HomePage';
import ContactPage from './pages/Contact/ContactPage';
import GrahamBioPage from './GrahamBioPage';
import KateBioPage from './KateBioPage';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop/>
        <Header/>
          <Routes>
            <Route index element={<HomePage/>}/>
            <Route path="/contact" element={<ContactPage/>}/>
            <Route path="/contact.html" element={<Navigate replace to="/contact"/>}/>
            <Route path="/graham" element={<GrahamBioPage/>}/>
            <Route path="/graham.html" element={<Navigate replace to="/graham"/>}/>
            <Route path="/kate" element={<KateBioPage/>}/>
            <Route path="/kate.html" element={<Navigate replace to="/kate"/>}/>
            <Route path='*' element={<Navigate replace to="/"/>} />
          </Routes>
      </Router>
      <Footer/>
    </React.Fragment>
  );
}

function Footer() {
  return(
    <div style={{ margin: "2rem", marginTop: "6rem", textAlign: "center" }}>
      © 2021 Warrnambool Natural Medicine Centre
    </div>
  );
}

export default App;
