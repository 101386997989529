import React from 'react';
import { useLocation } from "react-router-dom"
import WNMLogo from '../../resources/WNMLogo.jpg';
import './Header.css';
import {
    Link
} from 'react-router-dom';

export default function Header() {
    const location = useLocation();

    const isHome = location.pathname === '/';

    return(
    <div id="header-parent">
        <Link to="/" className="header-link">
            <div className="header-subparent">
                <img src={WNMLogo} height="80px"/>
                <div>
                    <h1 className="header-title">Warrnambool Natural Medicine Centre</h1>
                    <h3>Est. 1991</h3>
                </div>
            </div>
        </Link>
        <Link to={isHome ? "/contact" : "/"} style={{ textDecoration: "none" }}><div className="header-item header-button">{isHome ? "Contact Us" : "Home"}</div></Link>
    </div>
    );
}