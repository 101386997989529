import React from 'react';
import KateProfile from './resources/KatePenningtonProfile.jpg';

export default function KateBioPage() {
    return(
        <div className="container bio-container">
            <div className="bio-card">
                <img src={KateProfile} style={{ borderRadius: "50%", width: "300px" }}/>
                <div style={{ margin: "1rem", fontSize: "1.5rem", fontWeight: "600"}}>Kate Pennington</div>
                <div style={{ fontStyle: "italic" }}>Acupuncture, Herbal Medicine, Bowen Therapy</div>
            </div>
            <div className="bio-content">           
                Initially qualified as an Occupational Therapist, Kate has practiced Herbal Medicine, Acupuncture and Bowen Therapy since 1995 when she founded and ran the Portland Natural Medicine Centre. Although she has thirty years of clinical experience, Kate is still passionate about providing holistic health care.                <br/><br/>
                <br/><br/>
                <h2 style={{ marginBottom: "1rem", fontSize: "1.25rem", fontWeight: "600" }}>Bowen Therapy</h2>
                The Bowen technique is one of the newest and most exciting forms of therapeutic bodywork. It has a unique ability to view the body as a whole, and provide assessment and targeted intervention, with a characteristic soft-tissue input. Kate has researched and published articles on the Bowen Technique in various Natural Health Journals. She has also assisted in presenting lectures on the clinical application of Bowen technique around the country.
                She applies her experience and understanding of the technique to the treatment of musculoskeletal issues such as back, neck, hip, elbow, ankle, foot, shoulder, knee and wrist problems or any other issues with biomechanical dysfunction at their root.
                <br/><br/>
                <br/><br/>
                <h2 style={{ marginBottom: "1rem", fontSize: "1.25rem", fontWeight: "600" }}>Acupuncture & Herbal Medicine</h2>
                Acupuncture is an effective, natural and increasingly popular form of health care. It involves inserting very fine, sterile needles into specific sites (acupuncture points) to help induce healing. It is one of the oldest and most longstanding health care systems in the world.
                Kate practices acupuncture based on traditional Chinese concepts. She uses acupuncture and/or Herbal Medicine to facilitate normal and healthy physiological functioning – this means the therapy is applicable for treating a very broad range of health issues. Kate has both a Post Graduate Diploma and Masters of Applied Science in Acupuncture and she enjoys treating women’s health issues including providing support for fertility and hormonal issues.
                <br/><br/>
                <br/><br/>
                <h2 style={{ marginBottom: "1rem", fontSize: "1.25rem", fontWeight: "600" }}>Professional Associations</h2>
                <ul style={{ listStyleType: "disc", listStylePosition: "inside", textAlign: "left" }}>
                    <li>Chinese Medicine Practitioner - Australian Health Practitioner Regulation Agency (AHPRA)</li>
                    <li>Australian Natural Therapists Association (ANTA)</li>
                </ul>
            </div>
        </div>
    );
}