import React from 'react';
import GrahamProfile from './resources/GrahamPenningtonProfile.jpg';

export default function GrahamBioPage() {
    return(
        <div className="container bio-container">
            <div className="bio-card">
                <img src={GrahamProfile} style={{ borderRadius: "50%", width: "300px" }}/>
                <div style={{ margin: "1rem", fontSize: "1.5rem", fontWeight: "600"}}>Graham Pennington</div>
                <div style={{ fontStyle: "italic" }}>Naturopathy, Acupuncture, Herbal Medicine, Bowen Therapy</div>
            </div>
            <div className="bio-content">           
                Having spent more than thirty years running a busy multi-practitioner clinic, Graham is what you might call an experienced practitioner. He incorporates Bowen therapy, acupuncture, herbal medicine, naturopathy, and remedial therapy into his consultations.
                <br/><br/>
                <br/><br/>
                <h2 style={{ marginBottom: "1rem", fontSize: "1.25rem", fontWeight: "600" }}>Bowen Therapy</h2>
                The Bowen technique is one of the newest and most exciting forms of therapeutic bodywork. It has a unique ability to view the body as a whole, and provide assessment and targeted intervention, with the characteristic soft-tissue input. Graham is an international presenter and teacher, a published author, and is widely recognised for his expertise as a Bowen practitioner. Author of “A Textbook of Bowen Technique”, he has presented lectures on the clinical application of the technique in more than twenty countries.
                He applies his experience and his understanding of the technique to the treatment of musculoskeletal issues such as back, neck, hip, elbow, ankle, foot, shoulder, knee and wrist problems etc, or any other issues with biomechanical dysfunction at their root.
                <br/><br/>
                <br/><br/>
                <h2 style={{ marginBottom: "1rem", fontSize: "1.25rem", fontWeight: "600" }}>Acupuncture & Herbal Medicine</h2>
                Acupuncture is an effective, natural and increasingly popular form of health care. It involves inserting very fine, sterile needles into specific sites (acupuncture points) to help induce healing. It is one of the oldest and most longstanding health care systems in the world.
                Graham practices acupuncture based on traditional Chinese concepts. He uses acupuncture and/or Herbal Medicine to facilitate normal and healthy physiological functioning – this means the therapy is applicable for treating a very broad range of health issues.
                <br/><br/>
                <br/><br/>
                <h2 style={{ marginBottom: "1rem", fontSize: "1.25rem", fontWeight: "600" }}>Professional Associations</h2>
                <ul style={{ listStyleType: "disc", listStylePosition: "inside", textAlign: "left" }}>
                    <li>Chinese Medicine Practitioner - Australian Health Practitioner Regulation Agency (AHPRA)</li>
                    <li>Australian Acupuncture and Chinese Medicine Association (AACMA)</li>
                    <li>Australian Natural Therapists Association (ANTA)</li>
                </ul>
            </div>
        </div>
    );
}